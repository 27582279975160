import React from 'react'
import Email from '../Components/Email'

const EmailPage = () => {
  return (
    <>
      <Email />
    </>
  )
}

export default EmailPage
