import styled from 'styled-components'

export const HeroContainer = styled.div`
  background:#75A1BD;
  display:flex;
  justify-content:center;
  align-items: center;
  padding:0 30px;
  height: 1400px;
  position:relative;
  z-index:1;
`

export const HeroBg = styled.div`
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  width: 100%;
  height:100%;
  overflow:hidden;
  
`

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #fff;
`

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1300px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
    @media screen and (max-width: 1440px) {
      margin-top: -65vh;
    }
    
    @media screen and (max-width: 375px) {
      margin-top: -80vh;
    }

`

export const HeroH1 = styled.h1`
  color: #F7E3A1;
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`

export const HeroP = styled.p`
  margin-top: 24px;
  color: #F7E3A1;
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`

// export const BtnWrapper = styled.div`
//   margin-top: 32px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `

